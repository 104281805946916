import * as React from "react";
import DefaultLayout from "../components/layout";

const NotFoundPage = () => (
	<DefaultLayout>
		<h1>404</h1>
		<p>Página não encontrada :(</p>
	</DefaultLayout>
);

export default NotFoundPage;
